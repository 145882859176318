/** TODO: Rewrite */
/**

  Simple Carousel Control

  Nik Wakelin
  nik@codetocustomer.com

  http://codetocustomer.com

**/

class SimpleCarousel {

  default_options = { window_size : 3, scroll_increment : 1, autoscroll : false, autoscroll_period : 5, change_arrow_titles : true };

  constructor(container, options) {
    this.container = $(container);
    this.clip      = new Element("div", { "class" : "clip" });

    this.list  = this.container.down("ul");
    this.list.wrap(this.clip);

    this.items     = this.list.select("li.step");

    if (this.items.size() == 0) return;

    // remove nested lists
/*    nested_items = this.list.select("ul > li");
    this.items   = this.items.filter(function(e) {
      return (nested_items.indexOf(e) == -1);
    });*/

    this.prev_arrows = this.container.select(".arrow.prev");
    this.next_arrows = this.container.select(".arrow.next");

    this.arrows = this.prev_arrows.concat(this.next_arrows);

    this.options = { ...this.default_options, ...options };

    this.init_styles();

    this.current_index = 0;

    if (this.options.initial_position) this.scroll_to_position(this.options.initial_position, false);

    this.update_arrows();

    this.arrows.each(function(arrow) {

      arrow.observe("click", this.scroll.bindAsEventListener(this));

    }.bind(this));

    if (this.options.hide_arrows && this.options.window_size >= this.items.size()) {
      this.arrows.invoke("hide");
    }

    if (this.options.autoscroll) {
      this.init_autoscroll();
    }
  }

  init_autoscroll(period) {
    this.autoscroll_timeout = new PeriodicalExecuter(this.autoscroll.bind(this), this.options.autoscroll_period);
  }

  autoscroll() {
    var position = (this.current_index + 1) % this.items.size();

    if (this.moving) return;
    this.moving = true;
    this.scroll_to_position(position, true);

  }

  init_styles() {

     this.clip.setStyle({ position : "relative", "overflow" : "hidden", width: "100%" });

     this.list.setStyle({ position : "relative", "overflow" : "hidden",
                          width    : "99999px",  left         : "0px" });


    var padding = parseInt(this.items.first().getStyle("padding-left")) +
                    parseInt(this.items.first().getStyle("padding-right"));

    var margins = parseInt(this.items.first().getStyle("margin-left")||0) +
                      parseInt(this.items.first().getStyle("margin-right")||0);

    var item_width = Math.floor(this.clip.getWidth() / this.options.window_size);

    var inner_margins = Math.floor(margins * (this.options.window_size - 1)) / this.options.window_size;

    this.scroll_width = item_width + margins - inner_margins;
    this.item_width   = item_width - padding - inner_margins;

    this.items.each(function(s) {
      $(s).setStyle({ 'width' : this.item_width + "px", cssFloat : "left" });
    }.bind(this));
    /*this.items.invoke("setStyle", { 'width' : this.item_width + "px", 'float' : "left" });*/

    return;

  }

  update_arrows() {

    var prev = (this.current_index == 0) ? "addClassName" : "removeClassName";
    var next = (this.current_index + this.options.window_size >= this.items.size()) ? "addClassName" : "removeClassName";

    eval("this.prev_arrows.invoke('" + prev + "', 'disabled');");
    eval("this.next_arrows.invoke('" + next + "', 'disabled');");

    if (this.options.change_arrow_titles) {

      if (this.current_index == 0) {
        this.prev_arrows.invoke("update", "Step 1");
        this.next_arrows.invoke("update", "Step 2");
      } else if (this.current_index == this.items.size() - 1) {
        this.prev_arrows.invoke("update", "Step " + (this.items.size() - 1));
        this.next_arrows.invoke("update", "Step " + (this.items.size()));
      } else {
        this.prev_arrows.invoke("update", "Step " + (this.current_index));
        this.next_arrows.invoke("update", "Step " + (this.current_index + 2));
      }

    }

    return;

  }

  scroll(event) {

    if (this.autoscroll_timeout) this.autoscroll_timeout.stop();

    // mutex so we don't do partial moves
    if (this.moving) return;
    this.moving = true;

    var direction = (event.element().hasClassName("prev")) ? -1 : 1;

    var new_position  = this.current_index + (direction * this.options.scroll_increment);

    this.scroll_to_position(new_position, true);

    event.stop();
    return false;

  }

  scroll_to_position(new_position, animate) {

    var last_pos      = this.items.size() - this.options.window_size;

    if (new_position > last_pos) new_position = last_pos;
    if (new_position < 0) new_position = 0;

    // reached the end or the start
    if (new_position == this.current_index) {
      this.moving = false;
      return;
    }

    var offset = -1 * (this.scroll_width * (new_position - this.current_index));

    if (animate) {

      new Effect.Move(this.list, { x : offset, duration : 0.5,

                                  afterFinish : function() {

                                    this.undim();
                                    this.moving = false;

                                  }.bind(this) });

      this.dim();

    } else {

      var current_left = parseInt(this.list.getStyle("left"));

      this.list.setStyle({ left : (offset + current_left) + "px" });
      this.moving = false;

    }

    this.current_index = new_position;
    this.update_arrows();

    return;
  }

  dim() {
    this.items.invoke("setOpacity", 1);
  }

  undim() {
    this.items.invoke("setOpacity", 1);
  }

};
window['SimpleCarousel'] = SimpleCarousel;
