import Controller from "controllers/application_controller"
import { html, render } from 'lit-html';

const cmp = 'entry--search-component';

export default class extends Controller {
  static identifier = 'entry--search'
  static targets = ['pinIconTemplate']

  /**
   * The delegate object that will receive search result events.
   */
  delegate;

  searchService;
  query;
  results;
  selectedIndex = 0;

  connect() {
    this.addEventListener(this.element, 'click', this.onClick.bind(this))
    this.addEventListener(this.element, 'mousedown', this.onMouseDown.bind(this))
  }

  updateQuery(query) {
    this.query = query;
    this.results = this.searchService.search(query);
    this.selectedIndex = 0;
    this.render();
  }

  selectNextResult(direction) {
    this.selectedIndex = Math.min(
      Math.max(this.selectedIndex + (direction ? 1 : -1), 0),
      this.results.length - 1
    );
    this.render();
  }

  get selectedResult() {
    return this.results[this.selectedIndex]
  }

  onClick(event) {
    let item = event.target.closest('[data-index]');
    // Not sure why but there was one browser where the event.target was not an item.
    if (item) {
      this.delegate.didAcceptSearchResult(this.results[parseInt(item.dataset.index)]);
    }
  }

  onMouseDown(event) {
    event.preventDefault();
  }

  render() {
    render(this.template(), this.element);
  }

  template() {
    if (this.results.length == 0) {
      return html`
        <div class="${cmp}__empty">No results found</div>
      `
    }

    let contactsGroup = [], projectsGroup = [], tasksGroup = [];
    this.results.forEach((result, index) => {
      if (result.contact_id) {
        projectsGroup.push(this.itemTemplate(result, index))
      } else if (result.entries_count === undefined) {
        contactsGroup.push(this.itemTemplate(result, index))
      } else {
        tasksGroup.push(this.itemTemplate(result, index))
      }
    })

    let resultText = []
    if (projectsGroup.length) {
      resultText.push(`${projectsGroup.length}/${this.searchService.projects.length} projects`)
    }

    if (tasksGroup.length) {
      resultText.push(`${tasksGroup.length}/${this.searchService.tasks.length} tasks`)
    }

    if (contactsGroup.length) {
      resultText.push(`${contactsGroup.length}/${this.searchService.contacts.length} contacts`)
    }

    resultText = resultText.join(' and ')

    return html`
      ${contactsGroup}
      ${projectsGroup}
      ${projectsGroup.length && tasksGroup.length ? html`
        <div class="${cmp}__divider"></div>
      ` : ''}
      ${tasksGroup}
      <div class="${cmp}__footer">
        Showing ${resultText}
      </div>
    `
  }

  itemTemplate = (result, index) => html`
    <div class="${cmp}__item" ?selected=${this.selectedIndex === index} data-index=${index} data-id=${result.id}>
      <div class="${cmp}__item-content">
        <div>${result.contact_id || result.entries_count !== undefined ? '#' : '@'}${result.short_code}</div>
        ${result.contact_short_code ? html`
          <div>@${result.contact_short_code}</div>
        ` : ''}
      </div>
      ${result.pinned ? html`
        <span class="${cmp}__icon">
          <ui-icon name="pin"></ui-icon>
        </span>
      ` : ''}
    </div>
  `
}
