import PositionedController from './positioned_controller'

/**
 * This controller manages a template that can be attached to a container.
 *
 * Usage:
 *   <div data-controller="tooltip" data-tooltip-message-value="Hello world!">
 *     Element
 *   </div>
 *
 *   <div data-controller="tooltip">
 *     Element
 *     <template data-tooltip-target="template">
 *       Hello world!
 *     </template>
 *   </div>
 */
export default class extends PositionedController {
  static targets = [ "template" ]
  static values = { message: String, enabled: { type: Boolean, default: true } }

  _mouseenter;
  _mouseleave;

  connect() {
    super.connect();
    this.element.addEventListener('mouseenter', this._mouseenter = this.show.bind(this));
    this.element.addEventListener('mouseleave', this._mouseleave = this.hide.bind(this));
  }

  disconnect() {
    super.disconnect();

    if (this.hasPositionedTarget) {
      this.positionedTarget.remove();
    }

    this.element.removeEventListener('mouseenter', this._mouseenter);
    this.element.removeEventListener('mouseleave', this._mouseenter);
  }

  enabledValueChanged() {
    if (!this.enabledValue) {
      this.hide();
    }
  }

  show() {
    this.hide();

    if (!this.enabledValue) {
      return;
    }

    const element = this.createTooltipElement();
    if (element) {
      this.element.append(element);
    }
  }

  hide() {
    if (this.hasPositionedTarget) {
      this.positionedTarget.remove();
    }
  }

  createTooltipElement() {
    const element = document.createElement('div');
    element.setAttribute("data-tooltip-target", "positioned");
    element.style.position = 'absolute';

    if (this.hasTemplateTarget) {
      element.classList.add('tooltip__template');
      element.append(this.templateTarget.cloneNode(true))
    } else if (this.hasMessageValue) {
      element.classList.add('tooltip__message');
      element.innerHTML = this.messageValue
    } else {
      return null;
    }

    return element;
  }
}
