import Controller from './application_controller'

import {autoUpdate, computePosition, flip, offset} from '@floating-ui/dom';

/**
 * This controller manages a template that can be attached to a container.
 */
export default class extends Controller {
  static targets = [ "anchor", "positioned" ]
  static values = {
    placement: { type: String, default: 'bottom-start' }
  }

  cleanup = null;

  positionedTargetConnected(element) {
    this.cleanup = autoUpdate(this.anchor, this.positionedTarget, () => {
      computePosition(this.anchor, this.positionedTarget, this.positionOptions).then(({x, y}) => {
        Object.assign(element.style, {
          left: `${x}px`,
          top: `${y}px`
        })
      });
    });
  }

  positionedTargetDisconnected(element) {
    if (this.cleanup) {
      this.cleanup()
      this.cleanup = null;
    }
  }

  get positionOptions() {
    return {
      placement: this.placementValue,
      middleware: [ offset({ mainAxis: 4, alignmentAxis: -4 }), flip()]
    }
  }

  get anchor() {
    if (this.hasAnchorTarget) {
      return this.anchorTarget;
    }

    return this.element;
  }
}
