import Controller from "./application_controller"
/**
 * Behaviour for a checkbox input that toggles a state class when selected.
 * Enables the toggling of the checkbox input value by directly calling the controller.
 */
export default class extends Controller {
  static targets = ["input"];
  static classes = ["selected"];

  declare inputTarget: HTMLInputElement;
  declare selectedClass: string;

  initialize() {
    this.render();
  }

  render() {
    this.element.classList.toggle(this.selectedClass || 'selected', this.inputTarget.checked);
  }

  toggle(event: Event) {
    if (event.target !== this.inputTarget) {
      this.inputTarget.checked = !this.inputTarget.checked;
      this.inputTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    }

    this.render();
  }
}
