import Controller from "./application_controller";

export default class extends Controller {
  static targets = ["input", "selectedValues"];

  declare inputTargets: HTMLInputElement[];
  declare selectedValuesTarget: HTMLInputElement;

  valueStates: { [value: string]: boolean } = {};

  initialize() {
    this.refreshValueStates();
  }

  update() {
    var selected = [];
    var unselected = [];
    for (var value in this.valueStates) {
      if (this.valueStates[value]) {
        selected.push(value);
      } else {
        unselected.push(value);
      }
    }
    this.selectedValuesTarget.value = selected.join(',');
  }

  toggleItem(event) {
    let input = this.inputTargets.find(el => el === event.target);
    if (input) {
      this.valueStates[input.value] = input.checked;
    }
    this.update();
  }

  refreshValueStates() {
    this.inputTargets.forEach(input => {
      this.valueStates[input.value] = input.checked;
    });
    this.update();
  }
}

