import { Controller } from "@hotwired/stimulus";

const animationKeyframes = [
  { clipPath: 'polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)' },
  { clipPath: 'polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)', offset: 0.25 },
  { clipPath: 'polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)', offset: 0.5 },
  { clipPath: 'polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)', offset: 0.75 },
  { clipPath: 'polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)' }
];

/**
 * Progressively fills around a styled circle using clipPath animation.
 */
export default class extends Controller {
  static targets = ["circle"];
  static values = {
    duration: { type: Number, default: 5000 }
  }

  declare circleTarget: HTMLDivElement;
  declare durationValue: number;

  progressAnimation: Animation;

  initialize() {
    if (this.durationValue <= 0) {
      return;
    }

    this.progressAnimation = this.circleTarget.animate(animationKeyframes, {
      fill: 'forwards',
      easing: 'linear',
      duration: this.durationValue
    });

    this.progressAnimation.onfinish = (event) => {
      this.element.dispatchEvent(
        new CustomEvent(`progress-circle:completed`)
      );
    }
  }

  pause() {
    if (this.progressAnimation?.playState == 'running') {
      this.progressAnimation.pause();
    }
  }

  resume() {
    if (this.progressAnimation?.playState == 'paused') {
      this.progressAnimation.play();
    }
  }
}
