declare global {
  interface Window {
    MinuteDock: {
      Icons: Record<string, { path: string }>;
    };
  }
}

export class UiIcon extends HTMLElement {
  #svg: SVGElement;
  #title: SVGTitleElement;
  #use: SVGUseElement;

  constructor() {
    super();
    console.warn(window);
  }

  connectedCallback() {
    this.#svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.#title = document.createElementNS('http://www.w3.org/2000/svg', 'title')
    this.#use = document.createElementNS('http://www.w3.org/2000/svg', 'use')

    this.#svg.appendChild(this.#title);
    this.#svg.appendChild(this.#use);

    this.#svg.setAttribute('viewBox', '0 0 100 100');
    this.#svg.setAttribute('width', '100%');
    this.#svg.setAttribute('height', '100%');

    this.#use.setAttribute('href', this.iconHref + '#icon');
    this.#title.textContent = this.alt;

    this.attachShadow({ mode: 'open' }).appendChild(this.#svg);
  }

  get name() {
    return this.getAttribute('name');
  }

  get alt() {
    return this.getAttribute('alt') || '';
  }

  get iconHref() {
    return window.MinuteDock.Icons[this.name].path;
  }
}

customElements.define('ui-icon', UiIcon);
