import Controller from "./application_controller"

export default class extends Controller {
  static targets = ["input", "nested", "nestedInput"];

  declare inputTarget: HTMLInputElement;
  declare nestedInputTargets: HTMLInputElement[];
  declare nestedTargets: HTMLElement[];

  private lastIndex: number;

  initialize() {
    this.update();
  }

  /**
   * Updates the checked/render state of the group
   */
  update() {
      this.inputTarget.checked = this.hasAllChecked;
      this.inputTarget.indeterminate = !this.inputTarget.checked && this.hasSomeChecked;
  }

  cascade(event) {
    let changed = [];

    this.nestedInputTargets.forEach(input => {
      if (input.checked != this.inputTarget.checked) {
        input.checked = this.inputTarget.checked;
        changed.push(input);
      }
    });

    changed.forEach(input => {
      input.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    })
  }

  maybeToggleRange(event: MouseEvent) {
    const index = this.nestedTargets.indexOf(event.currentTarget as any);

    if (event.shiftKey && this.lastIndex !== undefined) {
      const from = Math.min(index, this.lastIndex), to = Math.max(index, this.lastIndex);
      const checked = !this.nestedInputTargets[index].checked;

      let changed = [];
      for (let i = from; i <= to; i++) {
        let target = this.nestedInputTargets[i];
        if (i !== index && target.checked != checked) {
          target.checked = checked;
          changed.push(target);
        }
      }
      changed.forEach(input => input.dispatchEvent(new CustomEvent('change', { bubbles: true })));
    }

    this.lastIndex = index;
   }

  get hasAllChecked(): boolean {
    return this.nestedInputTargets.find((input) => !input.checked) === undefined;
  }

  get hasSomeChecked(): boolean {
    return this.nestedInputTargets.find((input) => input.checked) !== undefined;
  }

  get hasNoneChecked(): boolean {
    return !this.hasSomeChecked;
  }
}
