import  { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  calendar = null;

  connect() {
    this.calendar = this.setupCalendarInstance();
  }

  disconnect() {
    // FIXME: Doesn't appear to be a way to remove these from the DOM
  }

  // TODO: Use a more modern calendar selector.
  setupCalendarInstance() {
    var minDate = null;

    if (window.time_lock_out_date && !window.user_is_time_admin) {
      minDate = new Date(window.time_lock_out_date.getFullYear(), window.time_lock_out_date.getMonth(), window.time_lock_out_date.getDate()+1);
    }

    Calendar.setup({
      dateField      : this.element,
      dateFormat     : '%b %e %Y',
      markFuture     : true,
      minDate        : minDate,
      selectHandler  : calendar => {
        if (calendar.date > new Date() || (minDate && calendar.date < minDate)) {
          // Allow picking future dates
          // calendar.shouldClose = false;
          // return false;
        }

        Calendar.defaultSelectHandler(calendar);
        this.dispatch('change', { prefix: false });
      }
    });
  }
}
