import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static identifier = "modal";

  static targets = ["dialog", "backdrop"];

  declare dialogTarget: HTMLDialogElement;
  declare backdropTarget: HTMLDivElement;


  initialize() {
    this.dialogTarget.open = true;
  }

  close() {
    this.element.remove();
  }
}
