import Controller from './application_controller'

/**
 * This controller manages a template that can be attached to a container.
 */
export default class extends Controller {
  static targets = [ "template", "container", "attached" ]
  static classes = [ "insert", "remove" ]

  targetConnected(target) {
    target.querySelector("[autofocus]")?.focus();
  }

  /**
   * Attaches a copy of the template to the container.
   */
  prepend() {
    window.requestAnimationFrame(t => {
      this.containerTarget.prepend(this.cloneTemplate());
    })
  }

  /**
   * Attaches a copy of the template to the container.
   * Has no effect if the container already has an element.
   */
  prependOnce(event) {
    if (this.hasAttachedTarget) {
      event.stopPropagation();
      return;
    }
    this.prepend()
  }

  append() {
    window.requestAnimationFrame(t => {
      this.containerTarget.append(this.cloneTemplate());
    })
  }

  appendOnce(event) {
    if (this.hasAttachedTarget) {
      event.stopPropagation();
      return;
    }
    this.append()
  }

  /**
   * Removes a copy of the template from the container.
   * By default removes the attached element that contains the element
   * that triggered the event. Can be modified with the `data-template-target-param`.
   */
  remove({ target, params }) {
    if (!this.hasAttachedTarget) {
      return;
    }

    const attachedTargets = this.attachedTargets;
    let targets;
    switch (params.target) {
      case "first":
        targets = [this.attachedTargets[0]];
        break;
      case "last":
        targets = [this.attachedTargets[this.attachedTargets.length - 1]];
        break;
      case "all":
        targets = attachedTargets;
        break;
      case "target":
      case "self":
      default:
        if (!attachedTargets.includes(target)) {
          target = attachedTargets.find(t => t.contains(target))
        }
        targets = [target];
        break;
    }

    if (this.hasRemoveClass) {
      if (this.hasInsertClass) {
        targets.forEach(target => {
          target.classList.remove(this.insertClass);
        });
      }

      targets.forEach(target => {
        target.classList.add(this.removeClass);
        target.addEventListener("animationend", () => target.remove(), { once: true });
      });
    } else {
      targets.forEach(target => {
        target.remove();
      });
    }
  }

  cloneTemplate() {
    const fragment = this.templateTarget.content.cloneNode(true);
    if (this.hasInsertClass) {
      const element = fragment.firstElementChild;
      element.classList.add(this.insertClass);
      element.addEventListener("animationend", () => {
        element.classList.remove(this.insertClass);
      }, { once: true });
    }
    return fragment;
  }
}