import Controller from './application_controller'

const EVENTS = ['turbo:before-fetch-request', 'turbo:fetch-request-error', 'turbo:frame-load', 'turbo:frame-missing', 'turbo:frame-render', 'turbo:submit-end'];

/**
 * There is a bug in Turbo Frames that turns off the busy/loading status before the response
 * body has been downloaded and applied. On slow connections, this makes animation/visual changes
 * a bit janky.
 *
  * This controller listens for Turbo Frame events and adds/removes a loading class to the element
 */
export default class extends Controller {
  static classes = ["loading"]

  connect() {
    this._callback = this.handleEvent.bind(this);
    EVENTS.forEach(type => {
      this.element.addEventListener(type, this._callback);
    });
  }

  disconnect() {
    EVENTS.forEach(type => {
      this.element.removeEventListener(type, this._callback);
    });
  }

  handleEvent(event) {
    switch (event.type) {
      case 'turbo:before-fetch-request':
        this.element.classList.add(this.hasLoadingClass ? this.loadingClass : 'turbo-loading');
        break;
      case 'turbo:fetch-request-error':
      case 'turbo:frame-load':
      case 'turbo:frame-render':
      case 'turbo:frame-missing':
      case 'turbo:submit-end':
        this.element.classList.remove(this.hasLoadingClass ? this.loadingClass : 'turbo-loading');
        break;
    }
  }
}