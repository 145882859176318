import Controller from './application_controller'

export default class extends Controller {
  static targets = ["select", "statusCount"]
  static classes = ["input"];

  connect() {
    if (!this.hasSelectTarget) {
      return;
    }

    this.choices = new Choices(this.selectTarget, {
      shouldSort: false,
      searchPlaceholderValue: "Type to search...",
      itemSelectText: "",
      allowHTML: true,
      renderChoiceLimit: 250,
      searchResultLimit: 250,
      searchFields: ['label', 'labelDescription'],
      classNames: {
        containerOuter: ['choices', ...this.inputClasses]
      },
      callbackOnCreateTemplates: (...tplArgs) => ({
        choice: (options, choice, ...args) => {
          let el = Choices.defaults.templates.choice.call(this, options, choice, ...args)
          for (let key in choice.element.dataset) {
            if (el.dataset[key] === undefined) {
              el.dataset[key] = choice.element.dataset[key]
            }
          }
          return el;
        },

        dropdown: (...args) => {
          let el = Choices.defaults.templates.dropdown.call(this, ...args)
          let total = this.selectTarget.options.length
          let limit = args[0].renderChoiceLimit

          if (total < limit) {
            return el;
          }

          let div = document.createElement('div');
          div.classList.add('choices__status');
          div.innerHTML = `Showing <span data-choices-target="statusCount">${limit}</span> / <span>${total}</span> results`;
          el.appendChild(div);
          return el;
        }
      })
    })

    this.addEventListener(this.selectTarget, 'showDropdown', (event) => {
      this.updateScrollable()
    })

    this.addEventListener(this.selectTarget, 'change', (event) => {
      this.choices.setChoiceByValue(this.selectTarget.value)
    })

    this.addEventListener(this.selectTarget, 'search', (event) => {
      this.updateStatus(event)
      this.updateScrollable()
    })
  }

  disconnect() {
    this.choices?.destroy()
  }

  updateStatus(event) {
    if (!this.hasStatusCountTarget) {
      return;
    }

    let count = event.detail.resultCount
    if (event.detail.value == '') {
      count = Math.min(this.selectTarget.options.length, this.choices.config.renderChoiceLimit)
    } else {
      count = Math.min(count, this.choices.config.searchResultLimit)
    }
    this.statusCountTarget.innerHTML = count
  }

  updateScrollable() {
    let results = this.element.querySelector('.choices__list--dropdown > .choices__list');
    results?.classList.toggle('is-scrollable', results.scrollHeight > results.clientHeight);
  }
}
