import Controller from "./application_controller"
/**
 * Behaviour for a checkbox input that toggles a state class when selected.
 * Enables the toggling of the checkbox input value by directly calling the controller. 
 */
export default class extends Controller {
  static targets = ["source", "mirror"];

  declare sourceTarget: HTMLInputElement;
  declare mirrorTargets: HTMLInputElement[];

  initialize() {
    this.reflect();
  }

  reflect() {
    this.mirrorTargets.forEach(input => {
      switch (this.sourceTarget.type) {
        case 'checkbox':
          switch (input.type) {
            case 'hidden':
              input.value = this.sourceTarget.checked ? '1' : '0';
              break;
            case 'checkbox':
              input.value = this.sourceTarget.value;
              input.checked = this.sourceTarget.checked;
              break;
          }
          break;
      }
    });
  }
}
