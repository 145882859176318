import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static identifier = "ui-flash";

  initialize() {
  }

  dismiss(event) {
    let el = this.element as HTMLDivElement;
    el.classList.add('animate-fade-up-out');

    el.addEventListener('animationend', () => {
      el.remove();
    }, { once: true });
  }
}
