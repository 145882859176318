import Controller from "./application_controller"

/**
 * Handles the initialization of a legacy behaviour class wrapper on the
 * element.
 */
export default class extends Controller {
  behaviour: any;

  connect() {
    this.behaviour = new (this.behaviourClass)(this.element);
  }

  get behaviourClass(): ObjectConstructor {
    const type = this.data.get('type');
    return type && typeof window[type] === 'function' && window[type];
  }
}
