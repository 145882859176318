import TemplateController from './template_controller'

/**
 * This controller manages a template that can be attached to a container.
 */
export default class extends TemplateController {
  static identifier = "nested-attributes"

  // Tracks the last ID generated for nested attributes.
  lastId

  /**
   * Extends the base template cloning to modify the form elements
   * to use a generated number for the nested ID fields.
   */
  cloneTemplate() {
    this.lastId = this.lastId ? this.lastId + 1 : Date.now()

    const fragment = super.cloneTemplate();
    fragment.querySelectorAll("input, select, textarea").forEach(input => {
      // We're looking for the last number in the name representing the index of the nested
      // record set. We replace it with the generated ID.
      // Note that we want to support records with parameters that are Arrays. So we match
      // the last [\d] sequence succeded by a [name], allowing for an [] or [\d] suffix
      // to that [name] parameter.
      input.name = input.name.replace(/\[\d+\](?=(\[[^\d]*\])+(\[\d+\])?$)/, `[${this.lastId}]`);
      console.log(input.name)
    });

    return fragment;
  }
}