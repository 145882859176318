import Controller from "controllers/application_controller"

export default class extends Controller {
  static identifier = 'entry'

  get contactId() {
    // Filter the search results based on the contact_id of the entry
    return this.contactSelect?.value
      || this.element.querySelector('[data-contact-id]')?.dataset.contactId;
  }

  set contactId(value) {
    if (!this.contactSelect) {
      return;
    }

    for (let i = 0; i < this.contactSelect.options.length; i++) {
      if (this.contactSelect.options[i].value == value) {
        this.contactSelect.selectedIndex = i;
        this.dispatch("change", {
          prefix: null,
          target: this.contactSelect,
          detail: { value }
        })
        return;
      }
    }
  }

  get contactSelect() {
    return this.element.querySelector('select[name*=contact_id]');
  }
}
