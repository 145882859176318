/**
 * Sets the style of target element based on a list of styles provided.
 *
 * @param {*} targetNode - DOM Element to set the style on.
 * @param {*} styleList - CSSStyleDeclaration (eg. getComputerdStyle) or an object with property-value pairs.
 * @param {string} priority - CSS priority to use when setting the style. (Default: 'important')
 */
export function setElementStyle(targetNode, styleList, priority = 'important') {
  const isDeclaration = styleList instanceof CSSStyleDeclaration;
  const keys = isDeclaration ? Array.from(styleList) : Object.keys(styleList);
  keys.forEach(style => {
    targetNode.style.setProperty(style, isDeclaration ? styleList.getPropertyValue(style) : styleList[style], priority);
  })
}

/**
 * Returns an object mapping the provided list of properties to their computed values on the targetNode.
 *
 * @param {*} targetNode
 * @param {*} propertyList
 * @returns
 */
export function getComputedStyles(targetNode, propertyList) {
  const computedStyle = window.getComputedStyle(targetNode);
  const styles = {}
  propertyList.forEach(property => {
    styles[property] = computedStyle.getPropertyValue(property);
  });
  return styles;
}
